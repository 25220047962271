var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "postList-wrapper" },
    [
      _c("list", {
        ref: "list",
        attrs: {
          searchUrl: _vm.searchUrl,
          searchParams: _vm.searchParams,
          headers: _vm.headers,
        },
        on: {
          "update:searchParams": function ($event) {
            _vm.searchParams = $event
          },
          "update:search-params": function ($event) {
            _vm.searchParams = $event
          },
        },
        scopedSlots: _vm._u([
          {
            key: "headerSlot",
            fn: function () {
              return [
                _c("v-button", {
                  attrs: { text: "新增", permission: "add" },
                  on: { click: _vm.create },
                }),
              ]
            },
            proxy: true,
          },
          {
            key: "searchSlot",
            fn: function () {
              return [
                _c("v-datepicker", {
                  attrs: {
                    label: "创建时间",
                    maxDate: _vm.maxDate,
                    startTime: _vm.searchParams.startDate,
                    endTime: _vm.searchParams.endDate,
                  },
                  on: {
                    "update:startTime": function ($event) {
                      return _vm.$set(_vm.searchParams, "startDate", $event)
                    },
                    "update:start-time": function ($event) {
                      return _vm.$set(_vm.searchParams, "startDate", $event)
                    },
                    "update:endTime": function ($event) {
                      return _vm.$set(_vm.searchParams, "endDate", $event)
                    },
                    "update:end-time": function ($event) {
                      return _vm.$set(_vm.searchParams, "endDate", $event)
                    },
                  },
                }),
                _c("v-input", {
                  attrs: { label: "标题/内容", width: 160 },
                  model: {
                    value: _vm.searchParams.topicTitle,
                    callback: function ($$v) {
                      _vm.$set(_vm.searchParams, "topicTitle", $$v)
                    },
                    expression: "searchParams.topicTitle",
                  },
                }),
                _c(
                  "v-select2",
                  _vm._b(
                    {
                      attrs: { label: "所属公司" },
                      on: { onChange: _vm.regionChange },
                      model: {
                        value: _vm.searchParams.regionId,
                        callback: function ($$v) {
                          _vm.$set(_vm.searchParams, "regionId", $$v)
                        },
                        expression: "searchParams.regionId",
                      },
                    },
                    "v-select2",
                    _vm.regionParams,
                    false
                  )
                ),
                _c("v-select", {
                  attrs: { label: "所属应用", options: _vm.topicTypeOps },
                  model: {
                    value: _vm.searchParams.topicType,
                    callback: function ($$v) {
                      _vm.$set(_vm.searchParams, "topicType", $$v)
                    },
                    expression: "searchParams.topicType",
                  },
                }),
                _c("v-select", {
                  attrs: { label: "状态", options: _vm.topicStatusOps },
                  model: {
                    value: _vm.searchParams.topicStatus,
                    callback: function ($$v) {
                      _vm.$set(_vm.searchParams, "topicStatus", $$v)
                    },
                    expression: "searchParams.topicStatus",
                  },
                }),
                _c("v-input", {
                  attrs: { label: "发布人", placeholder: "请输入完整姓名" },
                  model: {
                    value: _vm.searchParams.topicUser,
                    callback: function ($$v) {
                      _vm.$set(_vm.searchParams, "topicUser", $$v)
                    },
                    expression: "searchParams.topicUser",
                  },
                }),
                _c("v-input", {
                  attrs: { label: "手机号码", placeholder: "请输入完整手机号" },
                  model: {
                    value: _vm.searchParams.topicUserPhone,
                    callback: function ($$v) {
                      _vm.$set(_vm.searchParams, "topicUserPhone", $$v)
                    },
                    expression: "searchParams.topicUserPhone",
                  },
                }),
                _c(
                  "v-select2",
                  _vm._b(
                    {
                      attrs: { label: "所属组织" },
                      model: {
                        value: _vm.searchParams.orgId,
                        callback: function ($$v) {
                          _vm.$set(_vm.searchParams, "orgId", $$v)
                        },
                        expression: "searchParams.orgId",
                      },
                    },
                    "v-select2",
                    _vm.orgParams,
                    false
                  )
                ),
                _c(
                  "v-select2",
                  _vm._b(
                    {
                      attrs: { label: "所属项目" },
                      model: {
                        value: _vm.searchParams.communityId,
                        callback: function ($$v) {
                          _vm.$set(_vm.searchParams, "communityId", $$v)
                        },
                        expression: "searchParams.communityId",
                      },
                    },
                    "v-select2",
                    _vm.communityParams,
                    false
                  )
                ),
                _c("v-select", {
                  attrs: { label: "是否置顶", options: _vm.isTopOps },
                  model: {
                    value: _vm.searchParams.isTop,
                    callback: function ($$v) {
                      _vm.$set(_vm.searchParams, "isTop", $$v)
                    },
                    expression: "searchParams.isTop",
                  },
                }),
              ]
            },
            proxy: true,
          },
          {
            key: "operateSlot",
            fn: function (scope) {
              return [
                _c("v-button", {
                  attrs: { text: "编辑", type: "text", permission: "update" },
                  on: {
                    click: function ($event) {
                      return _vm.edit(scope.row)
                    },
                  },
                }),
                _c("v-button", {
                  attrs: {
                    text: scope.row.isTop === 1 ? "取消" : "置顶",
                    type: "text",
                    permission: "top",
                  },
                  on: {
                    click: function ($event) {
                      return _vm.updateTop(scope.row)
                    },
                  },
                }),
              ]
            },
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }