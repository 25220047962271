<!--
 * @Description: 内容发布--资讯帖子--帖子列表页面
 * @Author: 小广
 * @Date: 2019-06-19 13:49:39
 * @LastEditors: 小广
 * @LastEditTime: 2019-10-24 09:10:49
 -->
<template>
  <div class="postList-wrapper">
    <list
      ref="list"
      :searchUrl="searchUrl"
      :searchParams.sync="searchParams"
      :headers="headers"
    >
      <template #headerSlot>
        <v-button text="新增" permission="add" @click="create"></v-button>
      </template>
      <template #searchSlot>
        <v-datepicker
          label="创建时间"
          :maxDate="maxDate"
          :startTime.sync="searchParams.startDate"
          :endTime.sync="searchParams.endDate">
        </v-datepicker>
        <v-input label="标题/内容" v-model="searchParams.topicTitle" :width="160"></v-input>
        <v-select2 label="所属公司" v-model="searchParams.regionId" v-bind="regionParams" @onChange="regionChange"></v-select2>
        <v-select label="所属应用" v-model="searchParams.topicType" :options="topicTypeOps"></v-select>
        <v-select label="状态" v-model="searchParams.topicStatus" :options="topicStatusOps"></v-select>
        <v-input label="发布人" placeholder="请输入完整姓名" v-model="searchParams.topicUser"></v-input>
        <v-input label="手机号码" placeholder="请输入完整手机号" v-model="searchParams.topicUserPhone"></v-input>
        <v-select2 label="所属组织" v-model="searchParams.orgId" v-bind="orgParams"></v-select2>
        <v-select2 label="所属项目" v-model="searchParams.communityId" v-bind="communityParams"></v-select2>
        <v-select label="是否置顶" v-model="searchParams.isTop" :options="isTopOps"></v-select>
      </template>
      <template #operateSlot="scope">
        <v-button text="编辑" type="text" permission="update" @click="edit(scope.row)"></v-button>
        <v-button :text="scope.row.isTop === 1 ? '取消' : '置顶'" type="text" permission="top" @click="updateTop(scope.row)"></v-button>
      </template>
    </list>
  </div>
</template>

<script>
import { getPostListURL, updateTopPostURL } from './api'
import {
  topicStatusMap,
  topicStatusOps,
  isTopMap,
  isTopOps
} from './map'
import { getOrgListURL } from 'common/api'
import { communityParams, tenantParams } from 'common/select2Params'
import moment from 'moment'

export default {
  name: 'PostList',
  data () {
    return {
      maxDate: moment().format('YYYY-MM-DD'),
      searchUrl: getPostListURL,
      topicTypeOps: [],
      // 去除举报中
      topicStatusOps: topicStatusOps(1).slice(0, 3),
      isTopOps: isTopOps(1),
      communityParams: communityParams,
      regionParams: tenantParams,
      orgParams: {
        searchUrl: getOrgListURL,
        request: {
          text: 'orgName',
          value: 'id'
        },
        response: {
          text: 'orgName'
        }
      },
      searchParams: {
        topicTitle: '',
        topicType: undefined,
        topicStatus: undefined,
        topicUser: '',
        topicUserPhone: '',
        communityId: '',
        orgId: '',
        regionId: '',
        isTop: undefined,
        startDate: '',
        endDate: ''
      },
      headers: [
        {
          prop: 'topicContent',
          label: '内容'
        },
        {
          prop: 'topicTypeName',
          label: '所属应用'
        },
        {
          prop: 'relName',
          label: '发布人姓名'
        },
        {
          prop: 'userName',
          label: '发布人昵称'
        },
        {
          prop: 'phone',
          label: '手机号'
        },
        {
          prop: 'topicPraise',
          label: '点赞数',
          align: 'right'
        },
        {
          prop: 'topicMsgcnt',
          label: '评论数',
          align: 'right'
        },
        {
          prop: 'orgName',
          label: '所属组织',
          formatter (row) {
            return row.orgName || '/'
          }
        },
        {
          prop: 'communityName',
          label: '所属项目'
        },
        {
          prop: 'regionName',
          label: '所属公司'
        },
        {
          prop: 'topicStatusStr',
          label: '状态',
          formatter (row) {
            return topicStatusMap[row.topicStatus]
          }
        },
        {
          prop: 'isTopStr',
          label: '是否置顶',
          formatter (row) {
            return isTopMap[row.isTop]
          }
        },
        {
          prop: 'intime',
          label: '发布时间'
        }
      ]
    }
  },

  created () {
    this.resetTopicTypeOps()
  },

  methods: {
    create () {
      this.$router.push({
        name: 'postForm'
      })
    },
    edit (row) {
      this.$router.push({
        name: 'postForm',
        query: {
          id: row.id
        }
      })
    },

    resetTopicTypeOps () {
      this.topicTypeOps = [{
        text: '全部',
        value: undefined
      }]
    },

    async regionChange (data) {
      this.resetTopicTypeOps()
      this.searchParams.topicType = undefined
      const regionId = data && data.id
      if (regionId) {
        const url = `${API_CONFIG.butlerBaseURL}neighbor/getTopicSubjectByRegionId`
        const params = {
          regionId
        }
        const { status, data } = await this.$axios.get(url, { params })
        if (status === 100) {
          if (data && data.length) {
            data.forEach(item => {
              this.topicTypeOps.push({
                text: item.name,
                value: item.id
              })
            })
          }
        }
      }
    },

    // 更新置顶的操作
    async updateTop (row) {
      let message = row.isTop === 1 ? '是否确认将此帖子取消置顶？' : '是否确认将此帖子置顶？'
      let isOk = await this.$confirm(message, { title: '提示' })
      isOk && this.requestForUpdateTop(row)
    },

    // 更新置顶的请求
    requestForUpdateTop (row) {
      let _this = this
      this.$axios({
        method: 'POST',
        url: updateTopPostURL,
        headers: { 'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8' },
        data: {
          id: row.id,
          isTop: row.isTop === 0 ? 1 : 0
        }
      }).then(res => {
        if (res.status === '100') {
          _this.$refs.list.searchData()
          _this.$message.success('操作成功')
        }
      })
    }
  }
}
</script>
